// Here you can add other styles
#search{
    border-radius: 25px;
}
#basic-addon1 {
    border-radius: 5px 0px 0px 5px;
}
.header-nav .nav-link:hover, .header-nav .nav-link:focus {
    cursor: pointer;
}
.placeholder-right input[type="text"]::placeholder {
    text-align: right;
}