.cursor-pointer {
    cursor: pointer;
}

.h-110 {
    height: 190px;
}

.app-header-logo {
    margin-top: -35px;
}

.app-side-header-logo {
    margin-top: -35px;
    width: 100%;
    justify-content: center;
    display: flex;
}

.app-side-header-logo img {
    margin-top: -16px;
}

.hidden {
    display: none;
}

.app-breadcrumb {
    margin-top: -25px;
    background: white;
    margin-bottom: 20px;
    padding: 18px;
    border-bottom: 1px;

}

.btn-group-sale {
    height: 37px;
}

.drop-down-action {
    outline: none !important;
    background: none !important;
    border: none !important;
    -webkit-appearance: none;
}

.drop-down-action:focus {
    outline: none;
    display: none;
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.dropdown-menu {
    margin-block: unset;
    margin-left: -132px;
}

.dropdown-item:active {
    background: #B1B7C1;
}


.loader-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2rem;
}

.dt-pick {
    background: transparent !important;
}

.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
    padding-right: 23px !important;
}


.is-sticky {
    /* margin: auto; */
    /* position: fixed; */
    /* z-index: 50; */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 50;
    animation: 700s ease-in-out 0s normal none 1 running fadeInDown;
}

.-mt-n1 {
    margin-top: -6px;
}

.btn-grow {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.btn-grow:hover {
    transform: scale(1.1);

}

.outline-none-focus:focus {
    -webkit-box-shadow: none;
    outline: -webkit-focus-ring-color auto 0px;
    outline: none;
}








.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
}

.bi {
    vertical-align: -.125em;
    fill: currentColor;
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}